<template>
  <div>
    <img alt="Vue logo" src="../assets/logo.png">
  </div>
  <el-button @click="dialogFormVisible = true">addUser</el-button>
  <el-button type="primary">hahha</el-button>
  <el-button type="success">Success</el-button>
  <el-table :data=list.sj style="width: 100%">
    <el-table-column type="index" label="编号" width="180" />
    <el-table-column prop="name" label="Name" width="180" />
    <el-table-column prop="sex" label="性别" />
  </el-table>
  <el-dialog v-model="dialogFormVisible" title="addUser">
    <el-form :model="form">
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-input v-model="form.sex"></el-input>
      </el-form-item>
      <el-button @click="commitaddUser">提交</el-button>
      <el-button @click="dialogFormVisible=false">取消</el-button>
    </el-form>
  </el-dialog>
</template>

<script setup>
const dialogFormVisible = ref(false)
const list = reactive({
  sj: ""
})

const form = reactive({
  name: '',
  sex: ''
})
const getsj = () => {
  axios({
    method: 'get',
    url: 'http://124.222.214.74:8080/user/getAlluser',
  }).then(response => {
    // test=response.data.data
    console.log(response.data.data)
    list.sj = response.data.data
  });
}
const commitaddUser = () => {
  axios({
    method: 'post',
    url: 'http://124.222.214.74:8080/user/addUser',
    data: {
      name: form.name,
      sex: form.sex
    }
  })
  setTimeout(1000);
  dialogFormVisible.value = false
  location.reload()
}

getsj()
</script>
<script>
import axios from 'axios'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router';

export default {
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>
